h1 {
  font-size: calc(3em + 1vw);
    text-transform: uppercase;
  font-weight: bold;
}

h2 {
    font-size: calc(2em + 1vw);
    text-transform: capitalize;
    font-weight: bold;
    color: var(--main-color);
  }

  h3 {
    font-size: calc(1.8em + 1vw);
    text-transform: capitalize;
    font-weight: 500;
  }

  h4 {
    font-size: 30px;
    text-transform: capitalize;
    font-weight: 500;
  }

  h5 {
    font-size: 25px;
    text-transform: capitalize;
    font-weight: 500;
  }

  p{
    line-height: 35px;
  }

  .gallery-content{
    columns: 5 320px;
  }
  
  .gallery-item{
    columns: 5 320px;
  }
  
  .gallery-content img{
    margin: 10px;
  }

  .pswp__img{
    object-fit: cover !important;
  }

  @media only screen and (max-width: 600px) {
    h1{
      font-size: calc(2em + 1vw);
    }
    h4{
      font-size: calc(1em + 1vw);
    }
    
  }

  .content-directorios{
    align-self: center;
  }