.pic-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .pic-wrapper:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.432);
  }

  
  figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    /*animation*/
    background-size: cover;
    
    animation: slideShow 24s linear infinite 0s;
    -o-animation: slideShow 24s linear infinite 0s;
    -moz-animation: slideShow 24s linear infinite 0s;
    -webkit-animation: slideShow 24s linear infinite 0s;
  }
  
  figurecaption {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
  }
  
  .pic-1 {
    opacity: 1;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover !important;
    background-repeat: no-repeat;
  }
  
  .pic-2 {
    animation-delay: 6s;
    -o-animation-delay: 6s;
    -moz--animation-delay: 6s;
    -webkit-animation-delay: 6s;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
  
  .pic-3 {
    animation-delay: 12s;
    -o-animation-delay: 12s;
    -moz--animation-delay: 12s;
    -webkit-animation-delay: 12s;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-repeat: no-repeat;
    background-size: cover !important;
  }
  
  .pic-4 {
    animation-delay: 18s;
    -o-animation-delay: 18s;
    -moz--animation-delay: 18s;
    -webkit-animation-delay: 18s;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-repeat: no-repeat;
    background-size: cover !important;
  }
  /* keyframes*/
  
  @keyframes 
  slideShow {  0% {
   opacity: 0;
   transform:scale(1);
   -ms-transform:scale(1);
  }
   5% {
   opacity: 1
  }
   25% {
   opacity: 1;
  }
   30% {
   opacity: 0;
   transform:scale(1.1);
   -ms-transform:scale(1.1);
  }
   100% {
   opacity: 0;
   transform:scale(1);
   -ms-transformm:scale(1);
  }
  }
   @-o-keyframes 
  slideShow {  0% {
   opacity: 0;
   -o-transform:scale(1);
  }
   5% {
   opacity: 1
  }
   25% {
   opacity: 1;
  }
   30% {
   opacity: 0;
   -o-transform:scale(1.1);
  }
   100% {
   opacity: 0;
   -o-transformm:scale(1);
  }
  }
   @-moz-keyframes 
  slideShow {  0% {
   opacity: 0;
   -moz-transform:scale(1);
  }
   5% {
   opacity: 1
  }
   25% {
   opacity: 1;
  }
   30% {
   opacity: 0;
   -moz-transform:scale(1.1);
  }
   100% {
   opacity: 0;
   -moz-transformm:scale(1);
  }
  }
   @-webkit-keyframes 
  slideShow {  0% {
   opacity: 0;
   -webkit-transform:scale(1);
  }
   5% {
   opacity: 1
  }
   25% {
   opacity: 1;
  }
   30% {
   opacity: 0;
   -webkit-transform:scale(1.1);
  }
   100% {
   opacity: 0;
   -webkit-transformm:scale(1);
  }
  }